body {
  background-color: #8e8c84;
  color: #3e3f3a;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 1.09375rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 0px;
  min-height: 100%;
  text-align: left;
  width: 100%;
}

footer a {
  color: inherit;
  opacity: 0.8;
  text-decoration: none;
}

footer a:hover {
  color: #000;
  opacity: 1;
}

footer li {
  display: inline-block;
  padding: 0 10px;
}

footer li:not(:last-child) {
  display: inline-block;
  margin-right: 0.5rem;
  padding: 0 10px;
}

footer ul {
  font-size: 18px;
  line-height: 1.6;
  list-style: none;
  margin-bottom: 0;
  padding: 0;
  text-align: center;
}

header {
  background-color: #8e8c84 !important;
}

main {
  background-color: #dfdfdd;
  max-width: 100% !important;
  min-height: 600px;
  min-width: 100% !important;
  padding: 20px;
  padding-bottom: 80px !important;
  padding-top: 88px;
  text-align: center;
}

#root {
  min-height: 100%;
  width: 100%;
}

.form_group_recaptcha > div > div {
  display: flex;
  place-content: center;
}

.heading {
  font-size: 2.1875rem !important;
  font-weight: 400 !important;
  line-height: 1.2 !important;
  margin-bottom: 0.5rem !important;
}

.leadText {
  font-weight: 400;
  line-height: 1.5;
}

.menuFlag {
  height: 24px;
  margin-right: 10px;
  width: 36px;
}
